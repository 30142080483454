var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},_vm._l((_vm.data),function(project){return _c('div',{key:project.dataset_id},[_c('div',{staticStyle:{"display":"inline-block"}},[_c('h4',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(project.name)+" Dataset progress "),_c('a',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.downloadDatasetBatch({
              id: project.dataset_id,
              name: project.name,
            })}}},[_vm._v(" Download ")])])]),_c('hr'),_vm._l((project.data),function(entry){return _c('b-card',{key:entry.id,staticClass:"overflow-hidden mt-4 custom-card",attrs:{"no-body":""}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"5"}},[_c('b-card-body',{staticClass:"text-left"},[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(entry.name))]),_c('b-card-text',[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(entry.city)+", "+_vm._s(entry.country))]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(entry.street))])])],1)],1),_c('b-col',{staticStyle:{"border-left":"solid #dee2e6 1px"},attrs:{"md":"7 text-left px-4"}},[_c('p',{staticClass:"mt-3"},[_vm._v("Progress")]),_c('b-progress',{attrs:{"value":(100 * entry.images_labelled) / entry.images_total,"variant":"info","height":"0.5em"}}),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(entry.images_labelled)+" / "+_vm._s(entry.images_total)+" images labeled ")]),_c('button',{staticClass:"btn btn-info m-1 mb-3",on:{"click":function($event){return _vm.downloadDataset({
                  name: entry.name,
                  dataset_id: project.dataset_id,
                  user_id: entry.user_id,
                })}}},[_vm._v(" download ")])],1)],1)],1)})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }